import { createSlice } from "@reduxjs/toolkit"

const INDIVI_STEPS = [
    "Investor type",
    "Personal details",
    "Suitability details",
    "KYC documents",
    "Review & Submit"
]

const ENTITY_STEPS = [
    "Investor type",
    "Entity details",
    "Suitability details",
    "Related party details",
    "KYC documents",
    "Review & Submit"
]

const setDataInLocalStorage = (state) => {
    const key = state?.token
    localStorage.setItem(`onboard/${key}`, JSON.stringify(state))
}

const defaultData = {
    dropDowns: {
        countries: [],
        financialGoals: [],
        industryPreferences: [],
        riskAppetites: [],
        cities: [],
        states: []
    },
    onboardType: "",
    token: "",
    prospect_id: "",
    email: "",
    direction: 0,
    activeStep: 0,
    investorType: "INDIVIDUAL",
    steps: INDIVI_STEPS,
    totalStepsCount: INDIVI_STEPS.length,
    personalDetails: {
        first_name: "",
        last_name: "",
        dob: "",
        country_id: "",
        address_1: "",
        address_2: "",
        postal_code: "",
        mobile_country_code: "",
        mobile: "",
        city_id: "",
        state_id: ""
    },
    individualSuitabilityDetails: {
        is_accredited_investor: "",
        is_pep: "",
        risk_appetite: "",
        financial_goals: [],
        industry_preference: [],
        investment_duration: "",
        is_us_indiv: ""
    },
    individualKycDetails: {
        proofOfAddress: null,
        proofOfIdentity: null,
        proofOfWealth: null,
        proofOfTaxResidency: null,
        sourceOfWealth: null,
        otherDocuments: []
    },
    entityDetails: {
        first_name: "",
        last_name: "",
        tax_id: "",
        company_registration_id: "",
        address_1: "",
        address_2: "",
        state_id: "",
        city_id: "",
        country_id: "",
        postal_code: "",
        dob: ""
    },
    entitySuitabilityDetails: {
        is_private_allowed: "",
        is_state_owned: "",
        is_prof: "",
        risk_appetite: "",
        financial_goals: [],
        industry_preference: []
    },
    relatedPartyDetails: [],
    entityKycDetails: {
        proofOfUboIdentity: [],
        proofOfUboAddress: [],
        proofOfAddress: null,
        proofOfIdentity: null
    }
}

const goNext = (state) => {
    state.activeStep = state.activeStep + 1
    state.direction = 1
}

const selfOnboardSlice = createSlice({
    name: "selfOnboard",
    initialState: defaultData,
    reducers: {
        setInitialState: (_, action) => {
            return action.payload || defaultData
        },
        storeVerifiedData: (state, action) => {
            state.token = action.payload.token
            state.prospect_id = action.payload.prospect_id
            state.email = action.payload.email
        },
        storeDropdowns: (state, action) => {
            state.dropDowns.countries = action.payload.countries
            state.dropDowns.riskAppetites = action.payload.riskAppetites
            state.dropDowns.financialGoals = action.payload.financialGoals
            state.dropDowns.industryPreferences =
                action.payload.industryPreferences
        },
        setCities: (state, action) => {
            state.dropDowns.cities = action.payload
        },
        setStates: (state, action) => {
            state.dropDowns.states = action.payload
        },
        goBack: (state) => {
            state.activeStep = state.activeStep - 1
            state.direction = -1
        },
        setInvestorType: (state, action) => {
            state.investorType = action.payload
            const steps =
                action.payload === "INDIVIDUAL" ? INDIVI_STEPS : ENTITY_STEPS
            state.totalStepsCount = steps.length
            state.steps = steps
        },
        goToNextInvestorType: (state, action) => {
            state.investorType = action.payload.investorType
            goNext(state)
            setDataInLocalStorage(state)
        },
        goToNextPersonalDetails: (state, action) => {
            state.personalDetails = action.payload
            goNext(state)
            setDataInLocalStorage(state)
        },
        goToNextIndividualSuitability: (state, action) => {
            state.individualSuitabilityDetails = action.payload
            goNext(state)
            setDataInLocalStorage(state)
        },
        saveIndivProofOfAddress: (state, action) => {
            state.individualKycDetails.proofOfAddress = action.payload
            setDataInLocalStorage(state)
        },
        saveIndivProofOfIdentity: (state, action) => {
            state.individualKycDetails.proofOfIdentity = action.payload
            setDataInLocalStorage(state)
        },
        goToNextIndividualKyc: (state) => {
            goNext(state)
        },
        goToNextEntityDetails: (state, action) => {
            state.entityDetails = action.payload
            goNext(state)
            setDataInLocalStorage(state)
        },
        goToNextEntitySuitability: (state, action) => {
            state.entitySuitabilityDetails = action.payload
            goNext(state)
            setDataInLocalStorage(state)
        },
        savePartyDetails: (state, action) => {
            const prevList = state.relatedPartyDetails
            state.relatedPartyDetails = [action.payload, ...prevList]
            setDataInLocalStorage(state)
        },
        updatePartyDetails: (state, action) => {
            const updatedList = state.relatedPartyDetails.filter(
                (party) => party.id !== action.payload.id
            )
            state.relatedPartyDetails = [action.payload, ...updatedList]
            setDataInLocalStorage(state)
        },
        deletePartyDetails: (state, action) => {
            const updatedList = state.relatedPartyDetails.filter(
                (party) => party.id !== action.payload.id
            )
            state.relatedPartyDetails = updatedList
            setDataInLocalStorage(state)
        },
        goToNextRelatedParty: (state) => {
            goNext(state)
            setDataInLocalStorage(state)
        },
        goToNextEntityKyc: (state) => {
            goNext(state)
        },
        saveEntityProofOfAddress: (state, action) => {
            state.entityKycDetails.proofOfAddress = action.payload
            setDataInLocalStorage(state)
        },
        saveEntityProofOfIdentity: (state, action) => {
            state.entityKycDetails.proofOfIdentity = action.payload
            setDataInLocalStorage(state)
        },
        setUboIdentityProof: (state, action) => {
            state.entityKycDetails.proofOfUboIdentity = action.payload
            setDataInLocalStorage(state)
        },
        setUboAddressProof: (state, action) => {
            state.entityKycDetails.proofOfUboAddress = action.payload
            setDataInLocalStorage(state)
        },
        setOnboardType: (state, action) => {
            state.onboardType = action.payload
            localStorage.setItem("onboardType", state.onboardType)
        },
        saveIndivProofOfWealth: (state, action) => {
            state.individualKycDetails.proofOfWealth = action.payload
            setDataInLocalStorage(state)
        },
        saveIndivProofOfTaxResidency: (state, action) => {
            state.individualKycDetails.proofOfTaxResidency = action.payload
            setDataInLocalStorage(state)
        },
        saveIndivSourceOfWealth: (state, action) => {
            state.individualKycDetails.sourceOfWealth = action.payload
            setDataInLocalStorage(state)
        },
        saveIndivOtherDocuments: (state, action) => {
            state.individualKycDetails.otherDocuments = action.payload
            setDataInLocalStorage(state)
        },
    }
})

export const {
    goBack,
    goToNextEntityDetails,
    goToNextEntitySuitability,
    goToNextRelatedParty,
    goToNextEntityKyc,
    goToNextInvestorType,
    goToNextIndividualKyc,
    goToNextPersonalDetails,
    goToNextIndividualSuitability,
    setInvestorType,
    storeVerifiedData,
    storeDropdowns,
    setCities,
    setStates,
    savePartyDetails,
    saveIndivProofOfAddress,
    saveIndivProofOfIdentity,
    saveEntityProofOfAddress,
    saveEntityProofOfIdentity,
    updatePartyDetails,
    deletePartyDetails,
    setUboAddressProof,
    setUboIdentityProof,
    setInitialState,
    setOnboardType,
    saveIndivProofOfWealth,
    saveIndivProofOfTaxResidency,
    saveIndivSourceOfWealth,
    saveIndivOtherDocuments
} = selfOnboardSlice.actions

export default selfOnboardSlice.reducer
