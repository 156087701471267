import * as Yup from "yup"
import { AddOutlined } from "@mui/icons-material"
import { Button } from "components/common/Ui/Form"
import FormWrapper from "../../components/FormWrapper"
import { checkPdfFormat, checkPdfSize } from "../../utils"
import { useDispatch, useSelector } from "react-redux"
import {
    goToNextIndividualKyc,
    saveIndivProofOfAddress,
    saveIndivProofOfIdentity,
    saveIndivProofOfWealth,
    saveIndivProofOfTaxResidency,
    saveIndivSourceOfWealth,
    saveIndivOtherDocuments
} from "redux/slices/selfOnboardSlice"
import FormAction from "../KycFooterAction"
import { ConfirmDeleteDialog } from "components/common/Ui/Dialog"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import {
    deleteSelfOnboardKyc,
    saveSelfOnboardKyc
} from "services/prospectsServices"
import KycDocumentModal from "../KycDocumentModal"
import KycSectionWrapper from "../KycSectionWrapper"
import KycDocumentList from "../KycDocumentList"
import { Tooltip } from "@mui/material"
import ProofOfIdentityModal from "./ProofOfIdentityModal"
import { getDocumentRequirements } from "services/prospectsServices"

// ** Constants
const title = "KYC documents"
const subtitle =
    "To comply with regulatory requirements, we need you to upload Know Your Customer (KYC) documents. These documents will help verify your identity."
const SUCCESS_MSG = "Success! Your document is now safe and saved"
const FAILED_MSG = "Something went wrong! We couldn't save your document."
const SUCCESS_DEL_MSG = "Success! Your document is deleted"
const FAILED_DEL_MSG = "Something went wrong! We couldn't delete your document."

const fileSchema = Yup.mixed()
    .required("Document required")
    .test(
        "fileformat",
        "Supported document formats: pdf,jpg,jpeg,png",
        checkPdfFormat
    )
    .test("filesize", "Document size must not exceed 9 MB", checkPdfSize)

const MIN_MONTH = 6

const getMinExpiryDate = () => {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + MIN_MONTH)
    return currentDate
}

// ** Main comp
export default function IndividualKyc() {
    const dispatch = useDispatch()
    const { post, isLoading } = useSelfOnboardApi()
    const { post: deleteDoc, isLoading: isDeleting } = useSelfOnboardApi()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showIdentityModal, setShowIdentityModal] = useState(false)
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [deletePayload, setDeletePayload] = useState(null)
    const [documentRequirements, setDocumentRequirements] = useState([])
    const [showWealthModal, setShowWealthModal] = useState(false)
    const [showTaxResidencyModal, setShowTaxResidencyModal] = useState(false)
    const [showSourceOfWealthModal, setShowSourceOfWealthModal] = useState(false)
    const [showOtherDocumentsModal, setShowOtherDocumentsModal] = useState(false)
    const {
        prospect_id,
        individualKycDetails: {
            proofOfAddress,
            proofOfIdentity,
            proofOfWealth,
            proofOfTaxResidency,
            sourceOfWealth,
            otherDocuments
        }
    } = useSelector((state) => state.selfOnboard)

    const proofOfAddressSaved = proofOfAddress !== null
    const proofOfIdentitySaved = proofOfIdentity !== null
    const proofOfWealthSaved = proofOfWealth !== null
    const proofOfTaxResidencySaved = proofOfTaxResidency !== null
    const sourceOfWealthSaved = sourceOfWealth?.length > 0

    useEffect(() => {
        const fetchRequirements = async () => {
            const resp = await getDocumentRequirements();
            setDocumentRequirements(resp.data.body);
        }
        fetchRequirements();
    }, [])
    const handleSave = async (data) => {
        const field = Object.keys(data).at(0)
        const payload = new FormData()
        payload.append(field, data[field])

        const meta = { prospectId: prospect_id, field }

        if (field === "proofOfIdentity") {
            meta["expiryDate"] = data.expiryDate
            meta["proofOfIdentitySelect"] = data.proofOfIdentitySelect || ""
        }

        payload.append("prospectKycRequest", JSON.stringify(meta, null, 2))

        const resp = await post({ apiCaller: saveSelfOnboardKyc, payload })

        if (resp.data?.status) {
            toast.success(SUCCESS_MSG)
            const { proofOfAddress,
                proofOfIdentity,
                proofOfWealth,
                proofOfTaxResidency,
                sourceOfWealth,
                otherDocuments } = resp.data.body

            // ** Dispatch and close the modal
            if (field === "proofOfIdentity") {
                dispatch(saveIndivProofOfIdentity(proofOfIdentity))
                setShowIdentityModal(false)
            } else if (field === "proofOfAddress") {
                dispatch(saveIndivProofOfAddress(proofOfAddress))
                setShowAddressModal(false)
            } else if (field === "proofOfWealth") {
                dispatch(saveIndivProofOfWealth(proofOfWealth))
                setShowWealthModal(false)
            } else if (field === "proofOfTaxResidency") {
                dispatch(saveIndivProofOfTaxResidency(proofOfTaxResidency))
                setShowTaxResidencyModal(false)
            } else if (field === "sourceOfWealth") {
                dispatch(saveIndivSourceOfWealth(sourceOfWealth?.map(doc => ({ ...doc, field }))))
                setShowSourceOfWealthModal(false)
            } else if (field === "otherDocuments") {
                dispatch(saveIndivOtherDocuments(otherDocuments?.map(doc => ({ ...doc, field }))))
                setShowOtherDocumentsModal(false)
            }
        } else {
            toast.error(resp.data.errorMessage || FAILED_MSG)
        }
    }

    const deleteDocument = async () => {
        const payload = {
            prospectId: prospect_id,
            ...deletePayload
        }

        const resp = await deleteDoc({
            apiCaller: deleteSelfOnboardKyc,
            payload
        })

        if (resp?.data?.status) {
            toast.success(SUCCESS_DEL_MSG)
            if (deletePayload.field === "proofOfIdentity") {
                dispatch(saveIndivProofOfIdentity(null))
            } else if (deletePayload.field === "proofOfAddress") {
                dispatch(saveIndivProofOfAddress(null))
            } else if (deletePayload.field === "proofOfWealth") {
                dispatch(saveIndivProofOfWealth(null))
            } else if (deletePayload.field === "proofOfTaxResidency") {
                dispatch(saveIndivProofOfTaxResidency(null))
            } else if (deletePayload.field === "sourceOfWealth") {
                dispatch(saveIndivSourceOfWealth(sourceOfWealth.filter(doc => doc.id !== deletePayload.id)))
            } else if (deletePayload.field === "otherDocuments") {
                dispatch(saveIndivOtherDocuments(otherDocuments.filter(doc => doc.id !== deletePayload.id)))

            }
            setShowDeleteModal(false)
        } else {
            toast.error(resp?.data?.errorMessage || FAILED_DEL_MSG)
        }
    }

    const handleDeleteClick = (payload) => {
        setDeletePayload(payload)
        setShowDeleteModal(true)
    }

    const handleNext = () => {
        dispatch(goToNextIndividualKyc())
    }

    return (
        <>
            <FormWrapper title={title} subtitle={subtitle}>
                {documentRequirements?.map((section) => {
                    switch (section) {
                        case "proof_of_identity":
                            return (
                                <KycSectionWrapper
                                    key="proof_of_identity"
                                    title="Proof Of Identity"
                                    headerSlot={
                                        <Tooltip
                                            title={
                                                proofOfIdentitySaved
                                                    ? "Proof of identity document already uploaded. To upload new one, you must delete existing one"
                                                    : null
                                            }
                                        >
                                            <span>
                                                <Button
                                                    disabled={proofOfIdentitySaved}
                                                    onClick={() => setShowIdentityModal(true)}
                                                    variant="outlined"
                                                    startIcon={AddOutlined}
                                                >
                                                    Document
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <KycDocumentList
                                        data={
                                            proofOfIdentitySaved
                                                ? [
                                                    {
                                                        ...proofOfIdentity,
                                                        field: "proofOfIdentity"
                                                    }
                                                ]
                                                : []
                                        }
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                </KycSectionWrapper>
                            )

                        case "proof_of_address":
                            return (
                                <KycSectionWrapper
                                    key="proof_of_address"
                                    title="Proof Of Address"
                                    headerSlot={
                                        <Tooltip
                                            title={
                                                proofOfAddressSaved
                                                    ? "Proof of address document already uploaded. To upload new one, you must delete existing one"
                                                    : null
                                            }
                                        >
                                            <span>
                                                <Button
                                                    disabled={proofOfAddressSaved}
                                                    onClick={() => setShowAddressModal(true)}
                                                    variant="outlined"
                                                    startIcon={AddOutlined}
                                                >
                                                    Document
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <KycDocumentList
                                        data={
                                            proofOfAddressSaved
                                                ? [
                                                    {
                                                        ...proofOfAddress,
                                                        field: "proofOfAddress"
                                                    }
                                                ]
                                                : []
                                        }
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                </KycSectionWrapper>
                            )
                        case "proof_of_wealth":
                            return (
                                <KycSectionWrapper
                                    key="proof_of_wealth"
                                    title="Proof Of Wealth"
                                    headerSlot={
                                        <Tooltip
                                            title={
                                                proofOfWealthSaved
                                                    ? "Proof of wealth document already uploaded. To upload new one, you must delete existing one"
                                                    : null
                                            }
                                        >
                                            <span>
                                                <Button
                                                    disabled={proofOfWealthSaved}
                                                    onClick={() => setShowWealthModal(true)}
                                                    variant="outlined"
                                                    startIcon={AddOutlined}
                                                >
                                                    Document
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <KycDocumentList
                                        data={
                                            proofOfWealthSaved
                                                ? [
                                                    ...proofOfWealth.map(doc => ({ ...doc, field: "proofOfWealth" }))
                                                ]
                                                : []
                                        }
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                </KycSectionWrapper>
                            );

                        case "proof_of_tax_residency":
                            return (
                                <KycSectionWrapper
                                    key="proof_of_tax_residency"
                                    title="Proof Of Tax Residency"
                                    headerSlot={
                                        <Tooltip
                                            title={
                                                proofOfTaxResidencySaved
                                                    ? "Proof of tax residency document already uploaded. To upload new one, you must delete existing one"
                                                    : null
                                            }
                                        >
                                            <span>
                                                <Button
                                                    disabled={proofOfTaxResidencySaved}
                                                    onClick={() => setShowTaxResidencyModal(true)}
                                                    variant="outlined"
                                                    startIcon={AddOutlined}
                                                >
                                                    Document
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <KycDocumentList
                                        data={
                                            proofOfTaxResidencySaved
                                                ? [
                                                    {
                                                        ...proofOfTaxResidency,
                                                        field: "proofOfTaxResidency"
                                                    }
                                                ]
                                                : []
                                        }
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                </KycSectionWrapper>
                            );

                        case "source_of_wealth":
                            return (
                                <KycSectionWrapper
                                    key="source_of_wealth"
                                    title="Source Of Wealth"
                                    headerSlot={
                                        <Tooltip
                                            title={
                                                sourceOfWealthSaved
                                                    ? "Source of wealth document already uploaded. To upload new one, you must delete existing one"
                                                    : null
                                            }
                                        >
                                            <span>
                                                <Button
                                                    disabled={sourceOfWealthSaved}
                                                    onClick={() => setShowSourceOfWealthModal(true)}
                                                    variant="outlined"
                                                    startIcon={AddOutlined}
                                                >
                                                    Document
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <KycDocumentList
                                        data={sourceOfWealthSaved ? [
                                            ...sourceOfWealth.map(doc => ({ ...doc, field: "sourceOfWealth" }))
                                        ] : [

                                        ]}
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                </KycSectionWrapper>
                            );

                        case "other_documents":
                            return (
                                <KycSectionWrapper
                                    key="other_documents"
                                    title="Other Documents"
                                    headerSlot={
                                        <Button
                                            onClick={() => setShowOtherDocumentsModal(true)}
                                            variant="outlined"
                                            startIcon={AddOutlined}
                                        >
                                            Document
                                        </Button>
                                    }
                                >
                                    <KycDocumentList
                                        data={otherDocuments || []}
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                </KycSectionWrapper>
                            );
                        default:
                            return null
                    }
                })}
                <FormAction
                    handleNext={handleNext}
                    docSaved={
                        // Include only required documents in this condition
                        documentRequirements.every(req => {
                            if (req === "proof_of_identity") return proofOfIdentitySaved;
                            if (req === "proof_of_address") return proofOfAddressSaved;
                            if (req === "other_documents") return otherDocuments.length > 0;
                            return true; // Default to true for unknown requirements
                        })
                    }
                />
            </FormWrapper>
            <ConfirmDeleteDialog
                isLoading={isDeleting}
                handleCancelBtn={() => setShowDeleteModal(false)}
                showModal={showDeleteModal}
                handleDeleteBtn={deleteDocument}
            />
            <ProofOfIdentityModal
                schema={Yup.object({
                    proofOfIdentity: fileSchema,
                    proofOfIdentitySelect: Yup.string(),
                    expiryDate: Yup.date()
                        .min(
                            getMinExpiryDate(),
                            `Identity must have a minimum validity of ${MIN_MONTH} months`
                        )
                        .typeError("Valid date required")
                        .required("Expiry date required")
                })}
                isLoading={isLoading}
                initialValues={{
                    proofOfIdentity: null
                }}
                name="proofOfIdentity"
                showModal={showIdentityModal}
                closeModal={() => setShowIdentityModal(false)}
                title="Proof of Identity"
                handleSubmit={handleSave}
            />
            <KycDocumentModal
                isLoading={isLoading}
                schema={Yup.object({ proofOfAddress: fileSchema })}
                initialValues={{
                    proofOfAddress: null
                }}
                name="proofOfAddress"
                showModal={showAddressModal}
                closeModal={() => setShowAddressModal(false)}
                title="Proof of Address"
                handleSubmit={handleSave}
            />
            <KycDocumentModal
                isLoading={isLoading}
                schema={Yup.object({ proofOfWealth: fileSchema })}
                initialValues={{
                    proofOfWealth: null
                }}
                name="proofOfWealth"
                showModal={showWealthModal}
                closeModal={() => setShowWealthModal(false)}
                title="Proof of Wealth"
                handleSubmit={handleSave}
            />
            <KycDocumentModal
                isLoading={isLoading}
                schema={Yup.object({ proofOfTaxResidency: fileSchema })}
                initialValues={{
                    proofOfTaxResidency: null
                }}
                name="proofOfTaxResidency"
                showModal={showTaxResidencyModal}
                closeModal={() => setShowTaxResidencyModal(false)}
                title="Proof of Tax Residency"
                handleSubmit={handleSave}
            />
            <KycDocumentModal
                isLoading={isLoading}
                schema={Yup.object({ sourceOfWealth: fileSchema })}
                initialValues={{
                    sourceOfWealth: null
                }}
                name="sourceOfWealth"
                showModal={showSourceOfWealthModal}
                closeModal={() => setShowSourceOfWealthModal(false)}
                title="Source of Wealth"
                handleSubmit={handleSave}
            />
            <KycDocumentModal
                allowMultiple={true}
                isLoading={isLoading}
                schema={Yup.object({ otherDocuments: fileSchema })}
                initialValues={{
                    otherDocuments: null
                }}
                name="otherDocuments"
                showModal={showOtherDocumentsModal}
                closeModal={() => setShowOtherDocumentsModal(false)}
                title="Other Documents"
                handleSubmit={handleSave}
            />

        </>
    )
}
