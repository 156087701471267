import { getClientSlugBasedOnUserType } from "constant/helpersUtility"
import {
    Axios,
    publicSelfOnboardAxios,
    privateSelfOnboardAxios,
    publicSelfOnboardProspectAxios
} from "./config" // eslint-disable-next-line

export const prospectNewKYCGET = async () => {
    const slug = getClientSlugBasedOnUserType()
    var resultService = await Axios.get(
        "api/v1/prospect/get-all-prospect-kyc-dropdown/" + slug
    )
    if (resultService.data.status) {
        return resultService.data.body
    }
}

export const prospectAllGetData = async (prospectId) => {
    const promise0 = Axios.get(
        "/api/v1/prospect/get-prospect-personal/" + prospectId
    )
    const promise1 = Axios.get(
        "/api/v1/prospect/get-prospect-financial/" + prospectId
    )
    const promise2 = Axios.get(
        "/api/v1/prospect/get-prospect-professional/" + prospectId
    )
    const promise3 = Axios.get(
        "/api/v1/prospect/get-prospect-family/" + prospectId
    )
    const promise4 = Axios.get(
        "/api/v1/prospect/get-prospect-interest/" + prospectId
    )
    const promise5 = Axios.get(
        "/api/v1/prospect/get-prospect-kyc/" + prospectId
    )

    var resultService = await Promise.all([
        promise0,
        promise1,
        promise2,
        promise3,
        promise4,
        promise5
    ]).then(function (values) {
        return values
    })
    return resultService
}

export const prospectInterestGetID = async (id) => {
    var resultService = await Axios.get(
        `/api/v1/prospect/get-prospect-interest/${id}`
    )
    if (resultService.data.status) {
        return resultService.data.body
    } else {
        return { new: true }
    }
}

export const prospectKYCGetID = async (id) => {
    var resultService = await Axios.get(
        `/api/v1/prospect/get-prospect-kyc/${id}`
    )
    if (resultService.data.status) {
        return resultService.data.body
    } else {
        return { new: true }
    }
}

export const prospectsGetTags = async (moduleId) => {
    var resultService = await Axios.get(
        "api/v1/prospect/get-all-prospect-tags/" + moduleId
    )
    if (resultService.data.status) {
        return resultService.data.body
    } else {
        return []
    }
}

export const prospectInterestAddUpdate = async (passingValue) => {
    var resultAPI = await Axios.post(
        "/api/v1/prospect/add-prospect-interest",
        passingValue
    )
    return resultAPI
}

export const prospectDeleteFile = async (passingValue) => {
    var resultAPI = await Axios.post(
        "/api/v1/prospect/delete-prospect-kyc",
        passingValue
    )
    return resultAPI
}

export const downloadDOCUMENTS = async (passingValue) => {
    var resultAPI = await Axios.post(
        "/api/v1/prospect/download-s3",
        passingValue,
        { responseType: "arraybuffer" }
    )
    return resultAPI
}

export const submitKYCFORM = async (formData) => {
    var resultService = await Axios.post(
        `/api/v1/prospect/add-prospect-kyc`,
        formData,
        {
            headers: { "content-type": "multipart/form-data" } //transformRequest: formData => formData,
        }
    )
    return resultService
}

export const submitRCA = async (passingObj) => {
    var resultService = await Axios.post(
        `/api/v1/prospect/request-compliance-approval`,
        passingObj
    )
    return resultService
}

////////////////////////
/* PROSPECT PROFILE */
////////////////////////

export const getProspectKYCById = async (prospectId) => {
    const url = `/api/v1/prospect/get-prospect-kyc/${prospectId}`
    const resp = await Axios.get(url)
    return resp
}

export const getKycDropDowns = async () => {
    const slug = getClientSlugBasedOnUserType()
    const url = "api/v1/prospect/get-all-prospect-kyc-dropdown/" + slug
    var resp = await Axios.get(url)
    return resp
}

export const addProspectKyc = async (formData) => {
    const url = `/api/v1/prospect/add-prospect-kyc`
    const resp = await Axios.post(url, formData)
    return resp
}

export const getProspectTags = async () => {
    const slug = getClientSlugBasedOnUserType()
    const url = `api/v1/prospect/get-all-prospect-tags/${slug}`

    const resp = await Axios.get(url)
    return resp
}

export const getProspectInterestById = async (prospectId) => {
    const url = `/api/v1/prospect/get-prospect-interest/${prospectId}`
    const resp = await Axios.get(url)
    return resp
}

// NOTE: same api is used for adding/update
export const addProspectInterest = async (payload) => {
    const url = "/api/v1/prospect/add-prospect-interest"
    const resp = await Axios.post(url, payload)
    return resp
}

export const getProspectFinancialDropdowns = async () => {
    const slug = getClientSlugBasedOnUserType()
    const url = `/api/v1/prospect/getall-dropdown-prospect-financial/${slug}`
    const resp = await Axios.get(url)
    return resp
}

export const getProspectFinancial = async (prospectId) => {
    const url = `/api/v1/prospect/get-prospect-financial/${prospectId}`
    const resp = await Axios.get(url)
    return resp
}

export const addProspectFinancial = async (payload) => {
    const url = `/api/v1/prospect/add-prospect-financial`
    const resp = await Axios.post(url, payload)
    return resp
}

////////////////////
/* SELF ONBOARD*/
////////////////////

export const generateOnboardLink = async (payload) => {
    const url = "/create_self_registered_prospect"
    const resp = await privateSelfOnboardAxios.post(url, payload)
    return resp
}

export const updateSelfOnboardProspect = async (payload) => {
    const url = "/update_self_registered_prospect"
    const resp = await publicSelfOnboardAxios.post(url, payload)
    return resp
}

export const verifySelfOnboardLink = async (params) => {
    const url = "/verify_self_registration_hash"
    const resp = await publicSelfOnboardAxios.get(url, { params })
    return resp
}

export const fetchOnboardDropdowns = async (params) => {
    const url = "/get_registration_dropdown"
    const resp = await publicSelfOnboardAxios.get(url, { params })
    return resp
}

export const fetchStateByCountry = async (params) => {
    const url = "/get_registration_dropdown_state"
    const resp = await publicSelfOnboardAxios.get(url, { params })
    return resp
}

export const fetchCityByState = async (params) => {
    const url = "/get_registration_dropdown_city"
    const resp = await publicSelfOnboardAxios.get(url, { params })
    return resp
}

export const completeSelfRegistration = async (payload) => {
    const url = "/submit_self_registered_prospect"
    const resp = await publicSelfOnboardAxios.post(url, payload)
    return resp
}

export const saveSelfOnboardKyc = async (payload) => {
    const url = "api/v1/prospect/add-prospect-kyc"
    const resp = await publicSelfOnboardProspectAxios.post(url, payload)
    return resp
}

export const deleteSelfOnboardKyc = async (payload) => {
    const url = "api/v1/prospect/delete-prospect-kyc"
    const resp = await publicSelfOnboardProspectAxios.post(url, payload)
    return resp
}

export const getSelfRegistrationHash = async (payload) => {
    const url = "/api/v1/prospect/generate-self-registration-hash"
    const resp = await Axios.post(url, payload)
    return resp
}


export const getDocumentRequirements = async () => {
    const url = "api/v1/prospect/document-requirements"
    const resp = await publicSelfOnboardProspectAxios.get(url)
    return resp
}